@use '@ids/styles/scss/styles' as *;
@use '@ids/styles/scss/fonts/itauIcon.scss' as *;

@import "@angular/cdk/overlay-prebuilt.css";
@import "@angular/cdk/a11y-prebuilt.css";

@import "@ids/tokens/varejo/tokens.css";

html,
body,
.main-container {
  height: 100%;
}

.no-margin {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}